@import "vars"

.modal
  z-index: $z-modal
  position: fixed
  inset: 0
  left: 0
  right: 0
  bottom: 0
  top: 0
  overflow: auto

  &.-black
    background: $bg

  &:not([open]):not(.-open)
    display: none

  &.-part
    @media (min-width: $md)
      left: $rootlet-width + 1px